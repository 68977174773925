/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../../style/index.scss"
import { Col, Container, Row } from "react-bootstrap"

import root from "window-or-global";

const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} title={title} /> */}
      {/* <script src="/eCrypt.min.js"/> */}
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=f32cef2d-6155-428a-9b0a-6c6859709d25"> </script>
      <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" />
      <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-72525561-1"></script>
      <script defer type="text/javascript" dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];
 function gtag(){dataLayer.push(arguments);}
 gtag('js', new Date());
 gtag('config', 'UA-72525561-1');`}} />
      {/* <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: `{
           "@context": "https://schema.org",
           "@type": "Corporation",
           "name": "VostroNet",
           "url": "https://vostronet.com",
           "logo": "https://vostronet.com/static/logo.svg",
           "contactPoint": [{
             "@type": "ContactPoint",
             "telephone": "+61-2-8319-2299",
             "contactType": "sales",
             "areaServed": ["AU","MY","SG","PH","US","GB"],
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "+64-9-887-1793",
             "contactType": "sales",
             "areaServed": "NZ",
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "1300-262-047",
             "contactType": "technical support",
             "contactOption": "TollFree",
             "areaServed": "AU",
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "0800-778-893",
             "contactType": "technical support",
             "contactOption": "TollFree",
             "areaServed": "NZ",
             "availableLanguage": "en"
           }],
           "sameAs": [
             "https://blog.vostronet.com",
             "https://www.facebook.com/vostronet.anz",
             "https://twitter.com/VostroNet",
             "https://www.instagram.com/vostronet/",
             "https://www.linkedin.com/company/vostronet/"
           ]
         }`
      }} /> */}
      {children}
      <script defer type="text/javascript" dangerouslySetInnerHTML={{
        __html: `window.zESettings = {
          "webWidget": {
            helpCenter: {
              title: {
                "*": "Flo Harris Lodge Internet"
              }
            },
            "chat": {
              "concierge": {
                  "name": "Flo Harris Lodge Internet",
                  "title": {
                      "*": "Flo Harris Lodge Internet"
                  }
              },
              "title": {
                "*": "Flo Harris Lodge Internet"
              },
              "tags": [
                  "floharris",
                  "floharrislodge",
                  " q2"
              ]
            },
            "color": {
                "theme": "#28A745",
                "launcher": "#28A745",
                "launcherText": "#FFFFFF",
                "button": "#28A745",
                "resultLists": "#28A745",
                "header": "#28A745",
                "articleLinks": "#28A745"
            },
            "answerBot": {
              "suppress": true,
              "title": {
                "*": "Flo Harris Lodge Internet"
              },
              "avatar": {
                "name": {
                  "*": "Flo Harris Lodge Bot"
                }
              }
            },
            "contactForm": {
              "title": {
                "*": "Flo Harris Lodge Internet"
              },
              "tags": [
                "floharris",
                "floharrislodge",
                "q2"
              ]
            },
            "contactOptions": {
                "enabled": true
            },
            "talk": {
              "title": {
                "*": "Flo Harris Lodge Internet"
              }
            }
          }
      }`
      }} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
